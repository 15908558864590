<template>
  <div>
    <ViewTemplateReportList :panel="panel" :reports="reports" />
  </div>
</template>

<script>
import ViewTemplateReportList from "@nixweb/nixloc-ui/src/component/template/ViewTemplateReportList";

export default {
  name: "RelatorioMaintenanceView",
  components: { ViewTemplateReportList },
  data() {
    return {
      panel: {
        module: "Manutenção",
        title: "Relatório",
        showFilter: false,
        showSearch: true,
        showButtons: false,
      },
      reports: {
        default: [
          {
            name: "Ordem de Serviço",
            description: "Filtre ordem de serviço por status, cliente, fornecedor e outros critérios...",
            routeName: "reportOrderService",
          },
        ],
        saved: [],
      },
    };
  },
};
</script>
